<template>
    <main class="c-app-content is-sidebar-tiny">
        <div class="row">
            <div class="col-12 col-md-12">
                <div class="card title mb-0">
                    <div class="card-body title-filter">
                        <div class="row">
                            <div class="col-lg-4 title-filte-left">
                                <h1 class="title-filter-title">
                                    <i class="b-table-icon fa fa-chart-pie mr-1"></i>
                                    <b v-if="$params.indicator[code].code">{{$params.indicator[code].code}}</b> {{$params.indicator[code].name}} <span v-if="$params.indicator[code].required" class="text-danger" style="float: none" >(*)</span>
                                </h1>
                            </div>
                            <div class="col-md title-filte-right">
                                <b-tabs class="c-tabs-button mr-3" small pills>
                                    <b-tab v-for="parent in this.dataClient" :key="parent.id" :title="parent.name" :active="parent.id == filterFormModel.client_id" @click='setTab(parent.id)'></b-tab>
                                </b-tabs>
                                <dropdown class="mr-1" :model="filterFormModel" attribute="group" :right-menu="true"
                                          type="bordered" icon="fa fa-list" :options="groupOptions" @change="load">
                                </dropdown>
                                <time-range-picker  class="pr-1" :model="filterFormModel" label="Thời gian" type='year' :defaultShortcut="2" @change="load" />
                                <button class="btn btn-gray btn-sm mr-1" @click="load">
                                    <i class="fa fa-sync-alt" ></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="loading" class="mt-3 p-1">
            <g-loading />
        </div>
        <div v-else>
            <div class="row">
                <div class="col-md-12">
                    <div class="card title mt-3">
                        <div class='card-header border-0'>
                            <div class="card-tools">
                                <button type="button" class="btn btn-tool" title="Xuất dữ liệu" @click='exportFile'>
                                    <b-spinner v-if="loading" small></b-spinner>
                                    <i v-else class="fa fa-download"></i> Xuất dữ liệu
                                </button>
                            </div>
                        </div>
                        <div class="card-body pt-0">
                            <apexchart height="300" :options="chartOptions" :series="chartData"></apexchart>
                            <div class="title-template-table table-responsive title-table-head-fixed title-table-chart">
                                <table class="table table-hover table-sm table-head-fixed table-bordered">
                                    <thead>
                                        <tr>
                                            <th class="text-center" v-if="filterFormModel.group !== 'year'">Năm</th>
                                            <th class="text-center" >{{groupLableOptions.find(o => o.value === filterFormModel.group).text}}</th>
                                            <th class="text-center">Số NKT được đánh giá</th>
                                            <th class="text-center">Số NKT có cải thiện</th>
                                            <th class="text-center">Tỷ lệ NKT cải thiện</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="filterFormModel.group === 'year'">
                                        <tr  v-if="items.length == 0"> 
                                            <td class="text-center text-danger" colspan="6">Không có dữ liệu!</td>
                                        </tr>
                                        <tr v-for="(item, index) in items" :key="index" >
                                            <td class='text-center'>{{item.label}}</td>
                                            <td class='text-center'>
                                                <span>{{item.mau}}</span>
                                            </td>
                                            <td class='text-center'>
                                                <span>{{item.tu}}</span>
                                            </td>
                                            <td class='text-center'>
                                                <span>{{item.per}}%</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr  v-if="tableData.length == 0"> 
                                            <td class="text-center text-danger" colspan="6">Không có dữ liệu!</td>
                                        </tr>
                                        <tr v-for="(item, index) in tableData" :key="index" >
                                            <td  class='text-center' v-if='item.count === 1' :rowspan="item.countTotal">{{item.year}}</td> 
                                            <td  class='text-left'>{{item.data.label}}</td> 
                                            <td class='text-center'>
                                                <span>{{item.data.mau}}</span>
                                            </td>
                                            <td class='text-center'>
                                                <span>{{item.data.tu}}</span>
                                            </td>
                                            <td class='text-center'>
                                                <span>{{item.data.per}}%</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts';
    import {saveAs} from 'file-saver';
    import Excel from 'exceljs';
    import moment from 'moment';
    import dashboard from './Dashboard';
    import Dropdown from '@/components/Dropdown';
    export default {
        mixins: [dashboard],
        components: {
            apexchart: VueApexCharts,
            Dropdown
        },
        data: function () {
            return {
                code: 'indicator02',
                loading: true,
                service: '/dashboard-detail',
                filterFormModel: {
                    client_id: null,
                    from: 0,
                    to: 0,
                    reset: null,
                    group: 'year',
                    qFrom: null,
                    qTo: null,
                    dataClient: [],
                },
                groupOptions: [
                    {value: 'year', text: "Theo năm"},
                    {value: 'gender', text: "Theo giới tính"},
                    {value: 'year_old', text: "Theo nhóm tuổi"},
                    {value: 'province_id', text: "Theo tỉnh/thành phố"},
                    {value: 'degree_disability', text: "Theo mức độ khuyết tật"},
                    {value: 'type', text: "Theo dạng tật"},
                    {value: 'improvement', text: "Theo lĩnh vực có cải thiện"},
                    {value: 'agent_orange_victim', text: "Theo nạn nhân da cam"},
                ],
                groupLableOptions: [
                    {value: 'year', text: "Năm"},
                    {value: 'gender', text: "Giới tính"},
                    {value: 'year_old', text: "Nhóm tuổi"},
                    {value: 'province_id', text: "Tỉnh/thành phố"},
                    {value: 'degree_disability', text: "Mức độ khuyết tật"},
                    {value: 'type', text: "Dạng tật"},
                    {value: 'improvement', text: "Lĩnh vực có cải thiện"},
                    {value: 'agent_orange_victim', text: "Nạn nhân da cam"},
                ],
                firstLoading: true,
                chartOptions: {chart: {type: 'bar'}},
                chartData: [],
                items: [],
                sum: {},
                avg: {},
            };
        },
        methods: {
            load() {
                if (!this.filterFormModel.client_id) {
                    return false;
                }
                this.loading = true;
                this.$service.get(`${this.service}/${this.code}`, {
                    params: this.filterFormModel
                }).then(response => {
                    this.params = response.data.params;
                    this.items = response.data.data;
                    this.tableData = [];
                    this.dataChart = this.params.dataChart;
                    this.chartData = [];
                    if (this.filterFormModel.group !== 'year') {
                        for (let itemParent of this.items) {
                            var itemChilds = itemParent.data;
                            var i = 1;
                            for (let item of itemChilds) {
                                this.tableData.push({'year': itemParent.year, 'data': item, 'count': i++, 'countTotal': itemChilds.length});
                            }
                        }
                        this.chartOptions = {
                            plotOptions: this.$params.chart.plotOptions,
                            colors: this.$params.chart.colors,
                            fill: {
                                opacity: 1
                            },
                            grid: {
                                yaxis: {
                                    lines: {
                                        show: false
                                    }
                                },
                            },
                            xaxis: {
                                categories: this.dataChart.map(item => item.year),
                                tooltip: {enabled: true}
                            },
                            chart: {toolbar: {
                                    show: true,
                                    offsetX: 0,
                                    offsetY: 0,
                                    tools: {
                                        download: true,
                                        selection: true,
                                        zoom: true,
                                        zoomin: true,
                                        zoomout: true,
                                        pan: true,
                                        reset: true | '<img src="/static/icons/reset.png" width="20">',
                                        customIcons: []
                                    },
                                    export: {
                                        csv: {
                                            filename: undefined,
                                            columnDelimiter: ',',
                                            headerCategory: 'category',
                                            headerValue: 'value',
                                            dateFormatter(timestamp) {
                                                return new Date(timestamp).toDateString()
                                            }
                                        },
                                        svg: {
                                            filename: 'csip_indicator02_' + moment().format('HHmm_DDMMYYYY'),
                                        },
                                        png: {
                                            filename: 'csip_indicator02_' + moment().format('HHmm_DDMMYYYY'),
                                        }
                                    },
                                    autoSelected: 'zoom'
                                }, zoom: {enabled: false}, type: 'bar'},
                            dataLabels: {
                                enabled: true,
                                formatter: function (val) {
                                    return val + '%';
                                },
                                offsetY: -25,
                                style: {
                                    fontSize: '12px',
                                    colors: ["#304758"]
                                }
                            },
                            tooltip: {
                                y: {
                                    formatter: function (val) {
                                        return val + '%';
                                    }
                                }
                            }
                        };
                        for (let dtText in this.params.dataLabel) {
                            this.chartData.push({
                                name: dtText,
                                type: 'bar',
                                data: this.dataChart.map(it => it['per_' + dtText] ? it['per_' + dtText] : 0)
                            });
                        }
                    } else {
                        this.chartOptions = {
                            plotOptions: this.$params.chart.plotOptions,
                            colors: this.$params.chart.colors,
                            fill: {
                                opacity: 1
                            },
                            grid: {
                                yaxis: {
                                    lines: {
                                        show: false
                                    }
                                },
                            },
                            xaxis: {
                                categories: this.items.map(item => item.label),
                                tooltip: {enabled: true}
                            },
                            chart: {toolbar: {
                                    show: true,
                                    offsetX: 0,
                                    offsetY: 0,
                                    tools: {
                                        download: true,
                                        selection: true,
                                        zoom: true,
                                        zoomin: true,
                                        zoomout: true,
                                        pan: true,
                                        reset: true | '<img src="/static/icons/reset.png" width="20">',
                                        customIcons: []
                                    },
                                    export: {
                                        csv: {
                                            filename: undefined,
                                            columnDelimiter: ',',
                                            headerCategory: 'category',
                                            headerValue: 'value',
                                            dateFormatter(timestamp) {
                                                return new Date(timestamp).toDateString()
                                            }
                                        },
                                        svg: {
                                            filename: 'csip_indicator02_' + moment().format('HHmm_DDMMYYYY'),
                                        },
                                        png: {
                                            filename: 'csip_indicator02_' + moment().format('HHmm_DDMMYYYY'),
                                        }
                                    },
                                    autoSelected: 'zoom'
                                }, zoom: {enabled: false}, type: 'bar'},
                            dataLabels: {
                                enabled: true,
                                formatter: function (val) {
                                    return val + '%';
                                },
                                offsetY: -25,
                                style: {
                                    fontSize: '12px',
                                    colors: ["#304758"]
                                }
                            },
                            tooltip: {
                                y: {
                                    formatter: function (val) {
                                        return val + '%';
                                    }
                                }
                            }
                        };
                        this.chartData = [];
                        this.chartData.push({
                            name: 'Tỷ lệ',
                            data: this.items.map(item => item.per ? item.per : 0),
                        });
                    }
                }).catch(error => {
                    if (error.response.data.message) {
                        this.$swal({
                            text: error.response.data.message,
                            icon: 'error'
                        });
                    }
                }).finally(() => {
                    this.loading = false;
                    this.firstLoading = false;
                });
            },
            exportFile: async function () {
                this.loading = true;
                let workbook = new Excel.Workbook();
                let sheet = workbook.addWorksheet('Dữ liệu');
                let cols = [];
                cols.push({header: "Năm", key: "year", width: 30});
                if (this.filterFormModel.group !== 'year') {
                    cols.push({header: "Danh mục", key: "label", width: 30});
                }
                cols.push({header: "Số NKT được đánh giá", key: "mau", width: 35});
                cols.push({header: "Số NKT có cải thiện", key: "tu", width: 35});
                cols.push({header: "Tỷ lệ NKT cải thiện (%)", key: "per", width: 35});
                sheet.columns = cols;
                if (this.filterFormModel.group === 'year') {
                    for (let item of this.items) {
                        let row = {
                            year: item.label,
                            mau: item.mau,
                            tu: item.tu,
                            per: item.per,
                        };
                        sheet.addRow(row);
                    }
                } else {
                    for (let item of this.tableData) {
                        let row = {
                            year: item.year,
                            label: item.data.label,
                            mau: item.data.mau,
                            tu: item.data.tu,
                            per: item.data.per,
                        };
                        sheet.addRow(row);
                    }
                }
                workbook.xlsx.writeBuffer({base64: true}).then(data => {
                    this.loading = false;
                    let fileName = 'csip_indicator02_' + moment().format('HHmm_DDMMYYYY');
                    saveAs(new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}),
                            `${fileName}.xlsx`);
                });
            },
        },
        mounted: async function () {
            this.listLoading = true;
            await this.$service.get(`${this.service}/overview`, {params: this.filterFormModel}).then(response => {
                if (response.data) {
                    let client = [];
                    for (let i in response.data.clients) {
                        if (response.data.clients[i].type != 'sub') {
                            client.push(response.data.clients[i])
                        }
                    }
                    this.dataClient = client;
                    if (!this.filterFormModel.client_id) {
                        this.filterFormModel.client_id = this.clients[0].id;
                    }
                    this.load();
                }
            }).finally(() => {
                this.listLoading = false;
            });
        }
    }
</script>

<style lang="scss" scoped>
::v-deep .apexcharts-menu-item.exportCSV {
  display: none;
}
</style>